// AdminDashboard.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Route, Routes, Navigate } from 'react-router-dom'; 
import Sidebar from './Sidebar';
import Header from './Header';
import EntityManagement from './EntityManagement';
import ScheduleManagement from './ScheduleManagement';
import useToken from '../useToken';
import Logs from './Logs'; // Import the new Logs component
import './css/styles.css'; 
import ImportSchedule from './ImportSchedule';
import UserManagement from './UserManagement';

const AdminDashboard = () => {
  const [refreshKey, setRefreshKey] = useState(0);

  const handleImportSuccess = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const { userData } = useToken(); // Assuming userData contains username, role, assignedGroups
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode === 'true' || false;
  });
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event) => {
    // If the sidebar is open and the clicked target is outside the sidebar, close it
    if (isSidebarOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for outside clicks when the sidebar is open
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener on component unmount or when sidebar closes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  const toggleTheme = () => {
    // Trigger animation
    const rootElement = document.getElementById('root');
    rootElement.classList.add('animating'); // Add the animation class

    setTimeout(() => {
      setIsDarkMode((prevMode) => {
        const newMode = !prevMode;
        localStorage.setItem('isDarkMode', newMode);
        return newMode;
      });

      // Remove the animation class after the transition is complete
      setTimeout(() => {
        rootElement.classList.remove('animating');
      }, 600); // Match this with the duration of your CSS transition
    }, 100); // Small delay to ensure animation starts before the theme changes
  };

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (isDarkMode) {
      rootElement.classList.add('dark-mode');
    } else {
      rootElement.classList.remove('dark-mode');
    }
  }, [isDarkMode]); // This effect runs whenever `isDarkMode` changes

  const [stats, setStats] = useState({
    professors: 0,
    rooms: 0,
    groups: 0,
    subjects: 0,
  });

  const fetchStats = async () => {
    try {
      const [professorsRes, roomsRes, groupsRes, subjectsRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/professors/count`),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/rooms/count`),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/groups/count`),
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/subjects/subjects/count`),
      ]);

      setStats({
        professors: professorsRes.data.count,
        rooms: roomsRes.data.count,
        groups: groupsRes.data.count,
        subjects: subjectsRes.data.count,
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className={`dashboard`}>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} sidebarRef={sidebarRef} />
      <div className="main-content">
        <Header isDarkMode={isDarkMode} toggleTheme={toggleTheme} toggleSidebar={toggleSidebar} />
        
        <Routes>
        <Route path="import-schedule" element={<ImportSchedule />} />

          <Route
            path="welcome"
            element={
              <>
                {/* Welcome Message */}
                <div className="card p-6 rounded-lg shadow-lg mb-6 mt-6">
                  <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
                    Bine ai venit, {userData?.username}!
                  </h1>
                  {userData?.role === 'groupAdmin' && userData.assignedGroups && userData.assignedGroups.length > 0 && (
                    <p className="text-gray-500 mt-2">
                      Poți modifica doar grupurile: {userData.assignedGroups.join(', ')}
                    </p>
                  )}
                  <p className="text-gray-600 dark:text-gray-300 mt-4">
                    Iată o privire rapidă asupra panoului tău de administrare.
                  </p>
                </div>

                {/* Dashboard Stats */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                  {/* Groups Stat */}
                  <div className="card p-6 rounded-lg shadow-lg flex items-center">
                    <span className="material-icons text-blue-500 text-4xl">groups</span>
                    <div className="ml-4">
                      <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
                        {stats.groups}
                      </h2>
                      <p className="text-gray-600 dark:text-gray-300">Grupuri</p>
                    </div>
                  </div>

                  {/* Professors Stat */}
                  <div className="card p-6 rounded-lg shadow-lg flex items-center">
                    <span className="material-icons text-green-500 text-4xl">school</span>
                    <div className="ml-4">
                      <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
                        {stats.professors}
                      </h2>
                      <p className="text-gray-600 dark:text-gray-300">Profesori</p>
                    </div>
                  </div>

                  {/* Subjects Stat */}
                  <div className="card p-6 rounded-lg shadow-lg flex items-center">
                    <span className="material-icons text-red-500 text-4xl">book</span>
                    <div className="ml-4">
                      <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
                        {stats.subjects}
                      </h2>
                      <p className="text-gray-600 dark:text-gray-300">Subiecte</p>
                    </div>
                  </div>

                  {/* Rooms Stat */}
                  <div className="card p-6 rounded-lg shadow-lg flex items-center">
                    <span className="material-icons text-purple-500 text-4xl">meeting_room</span>
                    <div className="ml-4">
                      <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
                        {stats.rooms}
                      </h2>
                      <p className="text-gray-600 dark:text-gray-300">Săli</p>
                    </div>
                  </div>
                </div>
              </>
            }
          />
         <Route
  path="users"
  element={
    userData?.role === 'admin' ? (
      <UserManagement />
    ) : (
      <Navigate to="/admin/welcome" replace />
    )
  }
/>

            <Route
            path="entity"
            element={
              userData?.role === 'admin' ? (
                <EntityManagement />
              ) : (
                <Navigate to="/admin/welcome" replace /> // Redirect if not admin
              )
            }
          />    
          <Route
            path="logs"
            element={
              userData?.role === 'admin' ? (
                <Logs />
              ) : (
                <Navigate to="/admin/welcome" replace />
              )
            }
          />      
          <Route path="schedule" element={<ScheduleManagement userData={userData} />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminDashboard;
