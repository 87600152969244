import React, { useState } from 'react';
import axios from 'axios';
import './css/ImportSchedule.css';
import excelIcon from './../../assets/excel.svg'; // Adjust the path if needed

const ImportSchedule = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = React.createRef();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setMessage(`Selected file: ${selectedFile.name}`);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    setMessage(`Selected file: ${droppedFile.name}`);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setIsLoading(true);
    setMessage('');
    setErrors([]);

    try {
      const token = JSON.parse(localStorage.getItem('token')).token;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/import-schedule`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { message, conflicts } = response.data;
      setMessage(message);

      if (conflicts && conflicts.length > 0) {
        setErrors(conflicts);
      } else {
        setErrors([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message || 'Failed to import schedule.';
        const conflictErrors = error.response.data.conflicts || [];
        setMessage(errorMessage);
        setErrors(conflictErrors);
      } else {
        setMessage('Failed to import schedule.');
        console.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Function to download the Excel template
  const handleDownloadTemplate = () => {
    const templateUrl = `${process.env.REACT_APP_BACKEND_URL}/api/admin/download-template`;
    window.location.href = templateUrl;
  };

  return (
    <div className="import-schedule-container">
      <h2>Importează Orar</h2>
      <div
        className={`drop-zone ${isDragging ? 'dragging' : ''}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onClick={handleClick}
      >
        <p>{file ? `Selected file: ${file.name}` : 'Click or drag your Excel file here to upload'}</p>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".xlsx, .xls"
          className="file-input"
        />
      </div>

      <div className="button-row">
      <button onClick={handleDownloadTemplate} className="download-button">
  <img src={excelIcon} alt="Excel Icon" className="excel-icon" />
  Descarcă Template
</button>
        <button onClick={handleUpload} className="upload-button" disabled={isLoading}>
          {isLoading ? 'Importing...' : 'Încarcă Orar'}
        </button>
      </div>

      {isLoading && (
        <div className="loading-icon">
          <span className="material-icons">autorenew</span>
        </div>
      )}

      {message && <div className="message">{message}</div>}
      {errors.length > 0 && (
        <div className="error-list">
          <h3>Conflicts Detected:</h3>
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ImportSchedule;
