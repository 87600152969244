// ConfirmationModal.js
import React from 'react';
import './css/styles.css';


const ConfirmationModal = ({ 
  title, 
  message, 
  children, 
  onConfirm, 
  onClose, 
  onCloseModal, 
  confirmLabel, 
  cancelLabel, 
  showCloseButton, 
  isDarkMode 
}) => {
  return (
    <div className="fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="schedule-modal relative w-full max-w-xl mx-4 my-8 rounded-lg shadow-lg"
        style={{ maxHeight: '90vh' }}
      >
        <div className="p-6 overflow-y-auto" style={{ maxHeight: 'calc(90vh - 120px)' }}>
          {title && (
            <h3 className="text-2xl font-semibold mb-4 schedule-title">
              {title}
            </h3>
          )}
          {message && (
            <p className="mb-6 text-gray-700 dark:text-gray-300">{message}</p>
          )}
          <div>{children}</div>
        </div>
        <div className="flex justify-end space-x-3 px-6 pb-6">
          <button
            onClick={onClose}
            className="py-2 px-4 rounded-md bg-gray-300 hover:bg-gray-400 text-gray-800 dark:bg-gray-600 dark:hover:bg-gray-700 dark:text-white focus:outline-none"
          >
            {cancelLabel || 'Anulează'}
          </button>
          <button
            onClick={onConfirm}
            className="py-2 px-4 rounded-md bg-blue-600 hover:bg-blue-700 text-white focus:outline-none"
          >
            {confirmLabel || 'Confirmă'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
