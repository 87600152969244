import React from 'react';
import './css/styles.css';
import { useNavigate } from 'react-router-dom';  // To handle redirection after logout


function Header({ isDarkMode, toggleTheme, toggleSidebar }) {
  const navigate = useNavigate();


  const handleLogout = () => {
  
    // Clear any session data (such as token)
    localStorage.removeItem('token');
    // Redirect to login page
    navigate('/login');
  };

  return (
    <header className="header rounded-lg shadow-lg">
      <button className="burger-menu" onClick={toggleSidebar}>
      <span className="material-icons">menu</span> {/* This is the Material Icon for the burger menu */}
      </button>
      <h2>Admin Dashboard</h2>
      <div className="header-controls">
        <button className="theme-btn" onClick={toggleTheme}>
          {isDarkMode ? '☀️ Light Mode' : '🌙 Dark Mode'}
        </button>
        <button className="logout-btn" onClick={handleLogout}>
          <span className="material-icons">logout</span>
        </button>
      </div>
    </header>
  );
}

export default Header;
