import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/EntityManagement.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModal from './ConfirmationModal'; // Import your ConfirmationModal

const EntityManagement = () => {
  const [entityType, setEntityType] = useState('group');
  const [name, setName] = useState('');
  const [entities, setEntities] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const entitiesPerPage = 4;

  // State for modal and group options
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupOptions, setGroupOptions] = useState({
    hasSaturdayClasses: false,
    hasSixthLesson: false,
  });

  // State for dark mode (assuming you have a dark mode implementation)
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode === 'true' || false;
  });

  useEffect(() => {
    fetchEntities();
  }, [entityType]);

  // Fetch entities from the API
  const fetchEntities = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('token')).token;
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/${entityType}s`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setEntities(response.data);
    } catch (err) {
      console.error('Error fetching entities:', err);
      toast.error('Failed to fetch entities');
    }
  };

  // Handle adding a new entity
  const handleAddEntity = async (event) => {
    event.preventDefault();

    if (!name.trim()) {
      toast.warn('Entity name cannot be empty');
      return;
    }

    // Check for duplicate entity
    if (
      entities.some(
        (entity) => entity.name.toUpperCase() === name.toUpperCase()
      )
    ) {
      toast.error(
        `${entityType.charAt(0).toUpperCase() + entityType.slice(1)} already exists`
      );
      return;
    }

    try {
      const token = JSON.parse(localStorage.getItem('token')).token;
      const entityData = { name: name.toUpperCase() };

      // If adding a group, include the options
      if (entityType === 'group') {
        entityData.hasSaturdayClasses = groupOptions.hasSaturdayClasses;
        entityData.hasSixthLesson = groupOptions.hasSixthLesson;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/${entityType}s`,
        entityData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success(
        `${entityType.charAt(0).toUpperCase() + entityType.slice(1)} added successfully!`
      );
      setEntities([...entities, response.data]);
      setName('');
      setGroupOptions({
        hasSaturdayClasses: false,
        hasSixthLesson: false,
      });
    } catch (err) {
      console.error('Error adding entity:', err);
      toast.error('Error adding entity');
    }
  };

  // Handle deleting an entity
  const handleDeleteEntity = async (entityName) => {
    try {
      const token = JSON.parse(localStorage.getItem('token')).token;
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/${entityType}s/${entityName}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setEntities(entities.filter((entity) => entity.name !== entityName));
      toast.success(
        `${entityType.charAt(0).toUpperCase() + entityType.slice(1)} deleted successfully!`
      );
    } catch (err) {
      console.error('Error deleting entity:', err);
      toast.error('Error deleting entity');
    }
  };

  // Handle editing a group
  const handleEditGroup = (group) => {
    setSelectedGroup(group);
    setGroupOptions({
      hasSaturdayClasses: group.hasSaturdayClasses || false,
      hasSixthLesson: group.hasSixthLesson || false,
    });
    setShowEditModal(true);
  };

  // Handle saving edited group
  const handleSaveGroup = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('token')).token;
      const updatedGroup = {
        hasSaturdayClasses: groupOptions.hasSaturdayClasses,
        hasSixthLesson: groupOptions.hasSixthLesson,
      };
  
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/groups/${selectedGroup.name}`,
        updatedGroup,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Fetch the updated group data to refresh the frontend state
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/groups/${selectedGroup.name}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      setSelectedGroup(response.data);
      toast.success('Group updated successfully!');
      setShowEditModal(false);
    } catch (err) {
      console.error('Error updating group:', err);
      toast.error('Error updating group');
    }
  };
  
  

  // Filter entities based on search query
  const filteredEntities = entities.filter((entity) =>
    entity.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredEntities.length / entitiesPerPage);
  const paginatedEntities = filteredEntities.slice(
    (currentPage - 1) * entitiesPerPage,
    currentPage * entitiesPerPage
  );

  return (
    <div className={`entity-management-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Gestionează {entityType.charAt(0).toUpperCase() + entityType.slice(1)}s</h2>

      {/* Entity Type Selector */}
      <div className="entity-type-selector">
        <select
          value={entityType}
          onChange={(e) => {
            setEntityType(e.target.value);
            setCurrentPage(1); // Reset page to 1 when changing entity type
          }}
        >
          <option value="group">Group</option>
          <option value="professor">Professor</option>
          <option value="subject">Subject</option>
          <option value="room">Room</option>
        </select>
      </div>

      {/* Search Bar */}
      <div className="entity-search-bar">
        <input
          type="text"
          placeholder={`Search ${entityType}s...`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      {/* Add Entity Form */}
      <form className="entity-add-form" onSubmit={handleAddEntity}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={`Enter new ${entityType} name`}
        />

        <button className="btn-add" type="submit">
          Add {entityType}
        </button>
      </form>

      {/* Display Entities */}
      <div className="entity-row-container">
        {paginatedEntities.length > 0 ? (
          paginatedEntities.map((entity, index) => (
            <div key={index} className="entity-row">
              <span className="entity-name">{entity.name}</span>
             
              <div className="entity-actions">
                {/* Edit button only for groups */}
                {entityType === 'group' && (
                  <button
                    className="btn-edit"
                    onClick={() => handleEditGroup(entity)}
                  >
                    Edit
                  </button>
                )}
                <button
                  className="btn-delete"
                  onClick={() => handleDeleteEntity(entity.name)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No entities found.</p>
        )}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Prev
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages || totalPages === 0}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>

      {/* Edit Group Modal using ConfirmationModal */}
      {showEditModal && (
        <ConfirmationModal
          title={`Edit Group: ${selectedGroup.name}`}
          message="Update the group options below:"
          onConfirm={handleSaveGroup}
          onClose={() => setShowEditModal(false)}
          confirmLabel="Save"
          cancelLabel="Cancel"
          showCloseButton={true}
          isDarkMode={isDarkMode}
          onCloseModal={() => setShowEditModal(false)}
        >
         
        </ConfirmationModal>
      )}
    </div>
  );
};

export default EntityManagement;
