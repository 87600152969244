import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    try {
      const tokenString = localStorage.getItem('token');
      const userToken = JSON.parse(tokenString);
      return userToken?.token || null;
    } catch (error) {
      console.error("Error parsing token from localStorage:", error);
      return null;
    }
  };

  const getUserData = () => {
    try {
      const tokenString = localStorage.getItem('token');
      const userToken = JSON.parse(tokenString);
      if (userToken && userToken.token) {
        const tokenParts = userToken.token.split('.');
        if (tokenParts.length === 3) {
          const payload = JSON.parse(atob(tokenParts[1]));
          return {
            username: payload.username,
            role: payload.role,
            assignedGroups: payload.assignedGroups,
          };
        }
      }
    } catch (error) {
      console.error("Error decoding token payload:", error);
    }
    return null;
  };

  const [token, setToken] = useState(getToken());
  const [userData, setUserData] = useState(getUserData());

  const saveToken = (userToken) => {
    try {
      localStorage.setItem('token', JSON.stringify(userToken));
      setToken(userToken.token);
      const tokenParts = userToken.token.split('.');
      if (tokenParts.length === 3) {
        const payload = JSON.parse(atob(tokenParts[1]));
        setUserData({
          username: payload.username,
          role: payload.role,
          assignedGroups: payload.assignedGroups,
        });
      }
    } catch (error) {
      console.error("Error saving token:", error);
    }
  };

  return {
    setToken: saveToken,
    token,
    userData,
  };
}
