// Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import logoDark from './../../assets/logo-white.png';
import './css/styles.css';
import useToken from '../useToken'; // Import useToken

function Sidebar({ isOpen, sidebarRef }) {
  const { userData } = useToken(); // Ensure useToken is imported if it's used here

  return (
    <div ref={sidebarRef} className={`sidebar ${isOpen ? 'open' : ''}`}>
      <Link to="/">
        <img src={logoDark} alt="University Logo" className="app-logo" />
      </Link>
      <ul>
        <Link to="/admin/welcome">
          <li>Bun Venit</li>
        </Link>
        <Link to="/admin/schedule">
          <li>Gestionare Orar</li>
        </Link>
        
        {userData?.role === 'admin' && (
          <>
          <Link to="/admin/import-schedule">
          <li>Importează Orar</li>
        </Link>
            <Link to="/admin/entity">
              <li>Gestionare Entități</li>
            </Link>
            <Link to="/admin/logs">
              <li>Logs</li>
            </Link>
            <Link to="/admin/users" activeclassname="active">
              <li>User Management</li>
            </Link>
          </>
        )}
      </ul>

    </div>
  );
}

export default Sidebar;
