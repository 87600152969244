import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './css/UserManagement.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [addingUser, setAddingUser] = useState(false);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [assignedGroups, setAssignedGroups] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('token')).token;
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Error fetching users');
    }
  };

  const generatePassword = () => {
    const randomPassword = Math.random().toString(36).slice(-10);
    setPassword(randomPassword);
    toast.info(`Generated Password: ${randomPassword}`);
  };

  const handleAddUser = () => {
    setAddingUser(true);
    setEditingUser(null);
    setUsername('');
    setRole('');
    setAssignedGroups('');
    setPassword('');
  };

  const handleSave = async () => {
    if (!username || !role) {
      toast.error('Please fill in all required fields');
      return;
    }

    const newUser = {
      username,
      role,
      assignedGroups: assignedGroups.split(',').map((group) => group.trim()),
      password,
    };

    try {
      const token = JSON.parse(localStorage.getItem('token')).token;
      if (editingUser) {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users/${editingUser._id}`, newUser, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('User updated successfully');
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users`, newUser, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('User added successfully');
      }
      setEditingUser(null);
      setAddingUser(false);
      fetchUsers();
    } catch (error) {
      console.error('Error saving user:', error);
      toast.error('Error saving user');
    }
  };

  const handleDelete = async (userId) => {
    try {
      const token = JSON.parse(localStorage.getItem('token')).token;
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('User deleted successfully');
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error deleting user');
    }
  };

  const isMobile = window.innerWidth <= 600;

  return (
    <div className="user-management">
      <button className="add-user-button" onClick={handleAddUser}>
        Add User
      </button>

      {isMobile ? (
        // Mobile View: User Cards
        <div className="user-cards">
          {users.map((user) => (
            <div key={user._id} className="user-card">
              <h3>{user.username}</h3>
              <p><strong>Role:</strong> {user.role}</p>
              <p><strong>Assigned Groups:</strong> {user.assignedGroups.join(', ')}</p>
              <div className="action-buttons">
                <button className="edit-button" onClick={() => setEditingUser(user)}>Edit</button>
                <button className="delete-button" onClick={() => handleDelete(user._id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        // Desktop View: Table
        <table className="user-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Role</th>
              <th>Assigned Groups</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>{user.assignedGroups.join(', ')}</td>
                <td className="action-buttons">
                  <button className="edit-button" onClick={() => setEditingUser(user)}>Edit</button>
                  <button className="delete-button" onClick={() => handleDelete(user._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {(editingUser || addingUser) && (
        <div className="modal">
          <h3>{editingUser ? 'Edit User' : 'Add User'}</h3>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="" disabled>Select Role</option>
            <option value="admin">Admin</option>
            <option value="groupAdmin">Group Admin</option>
          </select>
          <input
            type="text"
            value={assignedGroups}
            onChange={(e) => setAssignedGroups(e.target.value)}
            placeholder="Assigned Groups (comma-separated)"
          />
          <input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password (leave empty to auto-generate)"
          />
          <button onClick={generatePassword}>Generate Password</button>
          <div className="button-container">
            <button className="save-button" onClick={handleSave}>Save</button>
            <button className="cancel-button" onClick={() => {
              setEditingUser(null);
              setAddingUser(false);
            }}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
