// App.js

import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import SearchBar from './components/SearchBar';
import Timetable from './components/Timetable';
import './App.css';
import logoLight from './assets/logo.png';
import logoDark from './assets/logo-white.png';
import Login from './components/login/Login';
import CurrentWeek from './components/CurrentWeek';
import useToken from './components/useToken';
import PrivateRoute from './components/PrivateRoute';
import AdminDashboard from './components/admin/AdminDashboard';
import AnimationSection from './AnimationSection';
import paypalIcon from './assets/paypal.png';

function Layout({ children, onSearch, isDarkMode, toggleTheme, selectedFilters, isAdmin }) {
  const location = useLocation();

  useEffect(() => {
    const rootElement = document.getElementById('root');
    const bodyElement = document.body;
    const htmlElement = document.documentElement;

    if (isDarkMode) {
      rootElement.classList.add('dark-mode');
      bodyElement.classList.add('dark-mode');
      htmlElement.classList.add('dark-mode');
    } else {
      rootElement.classList.remove('dark-mode');
      bodyElement.classList.remove('dark-mode');
      htmlElement.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const currentWeekType = CurrentWeek();

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>
      {!location.pathname.startsWith('/admin') && (
        <header className="app-header">
        <div className="logo-container">
          <Link to="/">
            <img
              src={isDarkMode ? logoDark : logoLight}
              alt="Orarul online pentru ASEM - programul cursurilor"
              className="app-logo"
            />
          </Link>
          <div className="slogan">
          <h2>" Creat de studenți, pentru studenți! "</h2>
        </div>
        </div>

        <SearchBar
          onSearch={onSearch}
          onToggleTheme={toggleTheme}
          isDarkMode={isDarkMode}
          selectedFilters={selectedFilters}
          isAdmin={isAdmin}
        />
      </header>
      
      )}
      {location.pathname !== '/admin/welcome' && (
        <div className="week-display" style={{ backgroundColor: currentWeekType === 'odd' ? '#77BD33' : 'orange' }}>
          {currentWeekType === 'odd' ? 'Săptămâna Impară' : 'Săptămâna Pară'}
        </div>
      )}
      {children}
      <footer className="app-footer">
        <p>
          &copy; {new Date().getFullYear()} ASEM. All rights reserved.
          Developed with ❤️ by&nbsp;&nbsp; <a className="credits" href="https://www.instagram.com/antonio._os/">Antonyo</a>
        </p>
        <div className="donate-buttons">
          {/* Custom "Buy me a coffee" button */}
          <a
            href="https://www.buymeacoffee.com/antonyo"
            target="_blank"
            rel="noopener noreferrer"
            className="buy-me-beer-button"
            style={{
              display: 'flex',
              padding: '10px 20px',
              margin: '10px',
              backgroundColor: '#FFDD00',
              color: '#000',
              borderRadius: '5px',
              textDecoration: 'none',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            ☕ Buy me a coffee
          </a>

          {/* PayPal donation button */}
          <a
            href="https://paypal.me/antonyoM5"
            target="_blank"
            rel="noopener noreferrer"
            className="paypal-donate-button"
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              padding: '10px 20px',
              margin: '10px',
              backgroundColor: '#0070ba',
              color: '#fff',
              borderRadius: '5px',
              textDecoration: 'none',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            <img src={paypalIcon} alt="PayPal" style={{ width: '20px', marginRight: '8px' }} />
            Donate on PayPal
          </a>
        </div>
      </footer>
    </div>
  );
}

function App() {
  const { token, setToken } = useToken();

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const storedFilters = localStorage.getItem('selectedFilters');
    return storedFilters ? JSON.parse(storedFilters) : { group: '', professor: '', room: '' };
  });
  const [schedule, setSchedule] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode === 'true' || false;
  });
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();

  const [isAdmin, setIsAdmin] = useState(false); // Add this line

  useEffect(() => {
    // Parse URL parameters on initial load
    const params = new URLSearchParams(location.search);
    const group = params.get('group');
    const professor = params.get('professor');
    const room = params.get('room');

    if (group !== null || professor !== null || room !== null) {
      setSelectedFilters({
        group: group || '',
        professor: professor || '',
        room: room || '',
      });
    } else if (!selectedFilters.group && !selectedFilters.professor && !selectedFilters.room) {
      // If no filters are set, fetch the first group with a schedule
      const fetchFirstNonEmptyGroup = async () => {
        try {
          const groupResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/options`);
          const groupNames = groupResponse.data.groups.map(group => group.name);

          for (const groupName of groupNames) {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/groups/${groupName}`);
            const schedule = response.data.schedule || [];
            if (schedule.length > 0) {
              // Found a group with non-empty schedule
              setSelectedFilters(prevFilters => ({ ...prevFilters, group: groupName }));
              break;
            }
          }
        } catch (error) {
          console.error('Error fetching groups:', error);
        }
      };

      fetchFirstNonEmptyGroup();
    }
  }, [location.search]);

  useEffect(() => {
    // Store selectedFilters in localStorage whenever it changes
    localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
  }, [selectedFilters]);

  const handleSearch = async filters => {
    setSelectedFilters(filters);
  };

  useEffect(() => {
    const { group, professor, room } = selectedFilters;

    const fetchSchedule = async () => {
      setLoadingSchedule(true);
      setError(null);
      try {
        let response;

        if (group) {
          // Fetch schedule for specific group
          let query = `${process.env.REACT_APP_BACKEND_URL}/api/groups/${group}`;
          const params = new URLSearchParams();

          if (professor) params.append('professor', professor);
          if (room) params.append('room', room);
          if (params.toString()) query += `?${params.toString()}`;

          response = await axios.get(query);
        } else if (professor && !room) {
          // Fetch schedules across all groups filtered by professor
          let query = `${process.env.REACT_APP_BACKEND_URL}/api/groups/schedule`;
          const params = new URLSearchParams();

          params.append('professor', professor);
          if (params.toString()) query += `?${params.toString()}`;

          response = await axios.get(query);
        } else if (room && !group && !professor) {
          // Fetch schedule for specific room
          response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/${room}/schedule`);
        } else if (professor && room && !group) {
          // Fetch schedules across all groups filtered by professor and room
          let query = `${process.env.REACT_APP_BACKEND_URL}/api/groups/schedule`;
          const params = new URLSearchParams();

          params.append('professor', professor);
          params.append('room', room);
          if (params.toString()) query += `?${params.toString()}`;

          response = await axios.get(query);
        } else {
          // No filters selected
          setSchedule([]);
          setLoadingSchedule(false);
          return;
        }

        setSchedule(response.data.schedule || []);
      } catch (error) {
        console.error('Error fetching schedule:', error);
        setSchedule([]);
      } finally {
        setLoadingSchedule(false);
      }
    };

    fetchSchedule();
  }, [selectedFilters]);

  const toggleTheme = () => {
    setIsDarkMode(prev => {
      const newMode = !prev;
      localStorage.setItem('isDarkMode', newMode);
      return newMode;
    });
  };

  return (
    <>
      <Layout
        onSearch={handleSearch}
        isDarkMode={isDarkMode}
        toggleTheme={toggleTheme}
        selectedFilters={selectedFilters}
        isAdmin={isAdmin}
      >
        <Routes>
          <Route
            path="/"
            element={
              <>
                {error && <div className="error-message">{error}</div>}
                {loadingSchedule ? (
                  <></>
                ) : selectedFilters.group || selectedFilters.professor || selectedFilters.room ? (
                  <Timetable
                    schedule={schedule}
                    selectedFilters={selectedFilters}
                    isProfessorView={!!selectedFilters.professor && !selectedFilters.group}
                  />
                ) : (
                  <AnimationSection />
                )}
              </>
            }
          />

          {/* Login route */}
          <Route path="/login" element={<Login setToken={setToken} />} />

          {/* Admin dashboard routes - Protected by PrivateRoute */}
          <Route
            path="/admin/*"
            element={
              <PrivateRoute token={token}>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
