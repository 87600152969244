import React, { useEffect, useState } from 'react';
import './AnimationSection.css';

const AnimationSection = ({ title = "Selectează o grupă 😊", message = "Te rog să selectezi o grupă pentru a vedea orarul respectiv!" }) => {
  // State to manage the width and height of the player
  const [playerSize, setPlayerSize] = useState({ width: '500px', height: '500px' });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs';
    script.type = 'module';
    document.body.appendChild(script);

    // Function to update player size based on screen width
    const updatePlayerSize = () => {
      if (window.innerWidth < 768) {
        setPlayerSize({ width: '200px', height: '200px' });
      } else {
        setPlayerSize({ width: '500px', height: '500px' });
      }
    };

    // Set the initial size based on screen width
    updatePlayerSize();

    // Add event listener to handle screen resize
    window.addEventListener('resize', updatePlayerSize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', updatePlayerSize);
  }, []);

  return (
    <div className="animation-container">
      <div className="animation-content">
        {/* Lottie Animation */}
        <dotlottie-player
          src="https://lottie.host/a634024f-d2b6-4d35-aa71-b9bd33f9bc2b/Qg0Ycq78Lb.json"
          background="transparent"
          speed="1"
          style={{ width: playerSize.width, height: playerSize.height }}
          loop
          autoplay>
        </dotlottie-player>

        {/* Informative Text */}
        <div className="info-text">
          <h2>{title}</h2>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default AnimationSection;
