import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/logs.css';

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [selectedAction, setSelectedAction] = useState('All');
  const [selectedUser, setSelectedUser] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 5;

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/logs`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLogs(response.data);
        setFilteredLogs(response.data); // Initialize filtered logs
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchLogs();
  }, []);

  // Filter logs based on selected action, user, and search term
  useEffect(() => {
    let updatedLogs = logs;

    if (selectedAction !== 'All') {
      updatedLogs = updatedLogs.filter((log) => log.action === selectedAction.toLowerCase());
    }

    if (selectedUser !== 'All') {
      updatedLogs = updatedLogs.filter((log) => log.performedBy === selectedUser);
    }

    if (searchTerm) {
      updatedLogs = updatedLogs.filter((log) =>
        Object.values(log.details || {}).some((detail) =>
          detail.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    setFilteredLogs(updatedLogs);
    setCurrentPage(1); // Reset to the first page on filter change
  }, [selectedAction, selectedUser, searchTerm, logs]);

  // Get unique list of usernames for filtering
  const uniqueUsers = Array.from(new Set(logs.map((log) => log.performedBy)));

  // Pagination logic
  const totalPages = Math.ceil(filteredLogs.length / logsPerPage);
  const paginatedLogs = filteredLogs.slice(
    (currentPage - 1) * logsPerPage,
    currentPage * logsPerPage
  );

  return (
    <div className="logs-container">
      <div className="filter-container">
        <label htmlFor="actionFilter">Filter by Action:</label>
        <select
          id="actionFilter"
          value={selectedAction}
          onChange={(e) => setSelectedAction(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Added">Added</option>
          <option value="Edited">Edited</option>
          <option value="Deleted">Deleted</option>
        </select>

        <label htmlFor="userFilter">Filter by User:</label>
        <select
          id="userFilter"
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          <option value="All">All</option>
          {uniqueUsers.map((user) => (
            <option key={user} value={user}>{user}</option>
          ))}
        </select>

        <label htmlFor="searchTerm">Search:</label>
        <input
          type="text"
          id="searchTerm"
          placeholder="Search details..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {window.innerWidth <= 600 ? (
  filteredLogs.length > 0 ? (
    filteredLogs.map((log) => (
      <div key={log._id} className="log-card">
        <div><label>Action:</label> {log.action}</div>
        <div><label>Entity Type:</label> {log.entityType}</div>
        <div><label>Entity ID:</label> {log.entityId}</div>
        <div><label>Performed By:</label> {log.performedBy}</div>
        <div><label>Details:</label> 
          {log.details
            ? Object.entries(log.details).map(([key, value]) => (
                <div key={key}>{`${key}: ${value}`}</div>
              ))
            : 'No additional details'}
        </div>
        <div><label>Timestamp:</label> {new Date(log.performedAt).toLocaleString()}</div>
      </div>
    ))
  ) : (
    <div className="no-logs">No logs found for the selected filters or search term.</div>
  )
) : (
  <div className="logs-table-wrapper">

      <table className="logs-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Entity Type</th>
            <th>Entity ID</th>
            <th>Performed By</th>
            <th>Details</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {paginatedLogs.length > 0 ? (
            paginatedLogs.map((log) => (
              <tr key={log._id}>
                <td>{log.action}</td>
                <td>{log.entityType}</td>
                <td>{log.entityId}</td>
                <td>{log.performedBy}</td>
                <td>
                  {log.details
                    ? Object.entries(log.details).map(([key, value]) => (
                        <div key={key}>{`${key}: ${value}`}</div>
                      ))
                    : 'No additional details'}
                </td>
                <td>{new Date(log.performedAt).toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="no-logs">
                No logs found for the selected filters or search term.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
)}
      <div className="pagination-controls">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Prev
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Logs;
