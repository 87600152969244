// ScheduleManagement.js
import React, { useState, useEffect, useMemo, memo } from 'react';
import SearchBar from './../SearchBar';
import axios from 'axios';
import Timetable from './../Timetable';
import './css/styles.css';
import ConfirmationModal from './ConfirmationModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import {jwtDecode} from 'jwt-decode';

function ScheduleManagement() {
  const location = useLocation();
  const [schedule, setSchedule] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    group: localStorage.getItem('selectedGroup') || '',
    professor: '',
    room: '',
  });
  const [existingSchedule, setExistingSchedule] = useState(null);
  const [groupOptions, setGroupOptions] = useState({
    hasSaturdayClasses: false,
    hasSixthLesson: false,
  });
  
  
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [formValues, setFormValues] = useState({
    subject: '',
    professor: '',
    room: '',
    weekType: 'both',
    time: '',
    type: '',
    groups: [],
    subgroup: '',
  });
  const [options, setOptions] = useState({
    subjects: [],
    professors: [],
    rooms: [],
    groups: [],
  });
  const [selectedEventId, setSelectedEventId] = useState(null);
  const timeSlots = useMemo(() => {
    const baseTimeSlots = [
      '08:00 - 09:30',
      '09:40 - 11:10',
      '11:20 - 12:50',
      '13:00 - 14:30',
      '14:40 - 16:10',
    ];
    return groupOptions?.hasSixthLesson ? [...baseTimeSlots, '16:20 - 17:50'] : baseTimeSlots;
  }, [groupOptions?.hasSixthLesson]);
  

// Și asigurați-vă că utilizați timeSlots în modalele de adăugare/editare

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [scheduleIdToDelete, setScheduleIdToDelete] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode === 'true' || false;
  });

  const [userData, setUserData] = useState(null);

  // Extract user data from token
  useEffect(() => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    if (userToken) {
      const decodedToken = jwtDecode(userToken.token);
      setUserData({
        username: decodedToken.username,
        role: decodedToken.role,
        assignedGroups: decodedToken.assignedGroups,
      });
    }
  }, []);

  const handleSearch = async (filters) => {
    setSelectedFilters(filters);
  };
  useEffect(() => {
    const fetchGroupDetails = async () => {
      if (selectedFilters.group) {
        console.log('Fetching group details for:', selectedFilters.group);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/groups/${selectedFilters.group}`
          );
          const groupData = response.data;
          console.log('Fetched group data:', groupData);
  
          // Asigură-te că valorile sunt boolean, nu undefined sau null
          setGroupOptions({
            hasSaturdayClasses: !!groupData.hasSaturdayClasses,
            hasSixthLesson: !!groupData.hasSixthLesson,
          });
          
        } catch (error) {
          console.error('Error fetching group details:', error);
        }
      }
    };
  
    fetchGroupDetails();
  }, [selectedFilters.group]);
  
  
  useEffect(() => {
    const { group, professor, room } = selectedFilters;

    if (group) {
      const fetchFilteredSchedule = async () => {
        try {
          let query = `${process.env.REACT_APP_BACKEND_URL}/api/groups/${group}`;
          const params = new URLSearchParams();

          if (professor) params.append('professor', professor);
          if (room) params.append('room', room);
          if (params.toString()) query += `?${params.toString()}`;

          const tokenString = localStorage.getItem('token');
          const userToken = JSON.parse(tokenString);
          const token = userToken?.token;

          const response = await axios.get(query, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setSchedule(response.data.schedule || []);
        } catch (error) {
          console.error('Error fetching schedule:', error);
        }
      };

      fetchFilteredSchedule();
    }
  }, [selectedFilters]);

  // Fetch available subjects, professors, rooms, and groups
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;

        // Fetch groups
        let fetchedGroups;
        if (userData) {
          if (userData.role === 'admin') {
            // Admin can access all groups
            const responseGroups = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/admin/groups`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            fetchedGroups = responseGroups.data;
          } else {
            // Group admins can access assigned groups
            const responseGroups = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/admin/groups`,
              {
                params: { names: userData.assignedGroups },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            fetchedGroups = responseGroups.data;
          }
        }

        // Fetch subjects
        const responseSubjects = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/subjects`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Fetch professors
        const responseProfessors = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/professors`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedProfessors = responseProfessors.data;

        // Fetch rooms
        const responseRooms = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/admin/rooms`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const fetchedRooms = responseRooms.data;

        setOptions({
          subjects: responseSubjects.data || [],
          professors: fetchedProfessors || [],
          rooms: fetchedRooms || [],
          groups: fetchedGroups || [],
        });
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    if (userData) {
      fetchOptions();
    }
  }, [userData]);

  const onCellClick = (cellData, event = null) => {
    setSelectedCell(cellData);

    // Get the schedules for the current cell (if any)
    const schedulesInCell = schedule.filter(
      (s) => s.day === cellData.day && s.time === cellData.time
    );

    if (event) {
      // An event was clicked directly, open edit modal for that event
      setExistingSchedule(event);
      setSelectedEventId(event._id);
      setFormValues({
        subject: event.subject || '',
        professor: event.professor || '',
        room: event.room || '',
        weekType: event.weekType || 'both',
        time: event.time || '',
        type: event.type || '',
        groups: [{ value: selectedFilters.group, label: selectedFilters.group }],
        subgroup: event.subgroup || '',
      });
      setShowEditModal(true);
    } else {
      // Cell was clicked, not directly on an event
      if (schedulesInCell.length === 0) {
        // No schedules in the cell, proceed to add new schedule
        setFormValues({
          subject: '',
          professor: '',
          room: '',
          weekType: 'both',
          type: '',
          time: cellData.time,
          groups: selectedFilters.group
            ? [{ value: selectedFilters.group, label: selectedFilters.group }]
            : [],
          subgroup: '',
        });
        setShowAddModal(true);
      } else if (schedulesInCell.length === 1) {
        const existingSchedule = schedulesInCell[0];
        const existingWeekType = existingSchedule.weekType;

        if (existingWeekType === 'both') {
          // Handle subgroups
          const existingSubgroup = existingSchedule.subgroup;
          if (!existingSubgroup) {
            // Cannot add another event if weekType is 'both' without subgroup
            setExistingSchedule(existingSchedule);
            setSelectedEventId(existingSchedule._id);
            setFormValues({
              subject: existingSchedule.subject || '',
              professor: existingSchedule.professor || '',
              room: existingSchedule.room || '',
              weekType: existingSchedule.weekType || 'both',
              time: existingSchedule.time || '',
              type: existingSchedule.type || '',
              groups: [{ value: selectedFilters.group, label: selectedFilters.group }],
              subgroup: existingSchedule.subgroup || '',
            });
            setShowEditModal(true);
          } else {
            // Allow adding event for the other subgroup
            const oppositeSubgroup =
              existingSubgroup === 'subgroup1' ? 'subgroup2' : 'subgroup1';
            setFormValues({
              subject: '',
              professor: '',
              room: '',
              weekType: 'both',
              type: '',
              time: cellData.time,
              groups: selectedFilters.group
                ? [{ value: selectedFilters.group, label: selectedFilters.group }]
                : [],
              subgroup: oppositeSubgroup,
            });
            setShowAddModal(true);
          }
        } else if (existingWeekType === 'even' || existingWeekType === 'odd') {
          // Allow adding an event of the opposite week type
          const availableWeekType = existingWeekType === 'even' ? 'odd' : 'even';
          setFormValues({
            subject: '',
            professor: '',
            room: '',
            weekType: availableWeekType,
            type: '',
            time: cellData.time,
            groups: selectedFilters.group
              ? [{ value: selectedFilters.group, label: selectedFilters.group }]
              : [],
            subgroup: '',
          });
          setShowAddModal(true);
        }
      } else if (schedulesInCell.length === 2) {
        // Both week types are already present, can only edit or delete existing schedules
        toast.info(
          'Această celulă este completă. Puteți edita sau șterge orarele existente.'
        );
      }
    }
  };

  // Function to handle group selection change
  const handleGroupChange = (selectedOptions) => {
    setFormValues({
      ...formValues,
      groups: selectedOptions || [],
    });
  };

  const baseURL = process.env.REACT_APP_BACKEND_URL; // Set base URL from environment variables

  const handleSave = async () => {
    try {
      const { day } = selectedCell;
      const selectedGroups = formValues.groups.map((group) => group.value);

      const updatedSchedule = {
        ...formValues,
        day,
        groups: selectedGroups,
      };

      const token = JSON.parse(localStorage.getItem('token')).token;
      const headers = { Authorization: `Bearer ${token}` };

      // Check for existing schedules in the same cell
      const schedulesInCell = schedule.filter(
        (s) => s.day === day && s.time === formValues.time
      );

      // Conflict checking
      const conflict = schedulesInCell.find(
        (s) =>
          s.weekType === formValues.weekType &&
          (!formValues.subgroup || s.subgroup === formValues.subgroup)
      );

      if (conflict && (!showEditModal || selectedEventId !== conflict._id)) {
        toast.error(
          `Există deja un orar de tip '${formValues.weekType}' în această celulă.`
        );
        return;
      }

      // Check for subgroup conflicts when weekType is 'both'
      if (formValues.weekType === 'both') {
        const subgroupConflict = schedulesInCell.find(
          (s) =>
            s.weekType === 'both' &&
            s.subgroup === formValues.subgroup &&
            (!showEditModal || selectedEventId !== s._id)
        );
        if (subgroupConflict) {
          toast.error(
            `Există deja un orar pentru subgrupa '${formValues.subgroup}' în această celulă.`
          );
          return;
        }
      }

      if (showEditModal && selectedEventId) {
        await axios.put(
          `${baseURL}/api/admin/schedules/${selectedEventId}`,
          updatedSchedule,
          { headers }
        );
        toast.success('Orarul a fost actualizat cu succes!');
      } else {
        await axios.post(`${baseURL}/api/admin/schedules`, updatedSchedule, {
          headers,
        });
        toast.success('Orarul a fost adăugat cu succes!');
      }

      setShowAddModal(false);
      setShowEditModal(false);
      setSelectedFilters((prev) => ({ ...prev })); // Refresh the schedule
    } catch (error) {
      console.error('Error saving schedule:', error);
      toast.error('Eroare la salvarea orarului');
    }
  };

  // Function to show delete confirmation modal
  const handleDeleteClick = (scheduleId) => {
    setScheduleIdToDelete(scheduleId);
    setShowDeleteModal(true);
  };

  // Function to confirm deletion
  const handleConfirmDelete = async () => {
    try {
      const tokenString = localStorage.getItem('token');
      const userToken = JSON.parse(tokenString);
      const token = userToken?.token;

      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/schedules/${scheduleIdToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Orarul a fost șters cu succes!');
      setSelectedFilters((prev) => ({ ...prev })); // Refresh the schedule
    } catch (error) {
      toast.error('Eroare la ștergerea orarului');
      console.error('Error deleting schedule:', error);
    } finally {
      setShowDeleteModal(false);
      setScheduleIdToDelete(null);
    }
  };

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '250px', // Set a fixed width
      height: '40px', // Set a fixed height
      minHeight: '40px',
      padding: '0 5px',
      fontSize: '16px',
      borderRadius: '4px',
      border: state.isFocused ? '1px solid #007bff' : '1px solid #ccc',
      boxShadow: state.isFocused ? '0 0 0 1px #007bff' : null,
      backgroundColor: isDarkMode ? '#444' : '#fff',
      color: isDarkMode ? '#ffffff' : '#333',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 6px',
      overflow: 'hidden',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? '#ffffff' : '#333',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#666' : '#e0e0e0',
      maxWidth: '100%',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: isDarkMode ? '#ffffff' : '#000',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    input: (provided) => ({
      ...provided,
      color: isDarkMode ? '#ffffff' : '#333',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDarkMode ? '#bbbbbb' : '#666',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#007bff'
        : isDarkMode
        ? '#444'
        : '#fff',
      color: state.isSelected ? '#fff' : isDarkMode ? '#ffffff' : '#333',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1000,
      backgroundColor: isDarkMode ? '#444' : '#fff',
      color: isDarkMode ? '#ffffff' : '#333',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '40px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: isDarkMode ? '#ffffff' : '#333',
      padding: '0 8px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
  };
  

  const handleClearSchedules = async () => {
    if (!selectedFilters.group) {
      toast.error('Vă rugăm să selectați un grup mai întâi.');
      return;
    }

    const confirmClear = window.confirm(
      `Sunteți sigur că doriți să ștergeți toate orarele pentru grupul "${selectedFilters.group}"? Această acțiune nu poate fi anulată.`
    );

    if (!confirmClear) {
      return;
    }

    try {
      const token = JSON.parse(localStorage.getItem('token')).token;

      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/groups/${selectedFilters.group}/schedules`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(
        `Toate orarele pentru grupul "${selectedFilters.group}" au fost șterse.`
      );
      setSelectedFilters((prev) => ({ ...prev })); // Refresh the schedule
    } catch (error) {
      toast.error('Eroare la ștergerea orarelor. Vă rugăm să încercați din nou.');
      console.error('Error clearing schedules:', error);
    }
  };
  useEffect(() => {
    console.log('Group Options Updated:', groupOptions);
  }, [groupOptions]);
  
  return (
    <div className={`schedule-management ${isDarkMode ? 'dark-mode' : ''}`}>
      <SearchBar
        onSearch={handleSearch}
        isAdmin={true}
        isDarkMode={isDarkMode}
        onToggleTheme={setIsDarkMode}
        customSelectStyles={customSelectStyles}
        userData={userData}
        options={options}
        selectedFilters={selectedFilters}
      />
<Timetable
  schedule={schedule}
  isAdmin={true}
  onCellClick={onCellClick}
  handleDelete={handleDeleteClick}
  handleClearSchedules={handleClearSchedules}
  selectedFilters={selectedFilters}
  groupOptions={groupOptions}
/>

      <ToastContainer
        theme="dark"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

{/* Add Pair Modal */}
{showAddModal && (
  <ConfirmationModal
    title="Adaugă pereche"
    message="Adaugă detaliile perechii."
    onConfirm={handleSave}
    onClose={() => setShowAddModal(false)}
    confirmLabel="Adaugă pereche"
    cancelLabel="Anulează"
    isDarkMode={isDarkMode}
  >
    <form className="space-y-4">
      {/* Row 1 */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        {/* Groups Multi-select */}
        <div className="flex flex-col flex-1">
          <label className="text-left mb-2 label-text">Grupuri:</label>
          <Select
            isMulti
            name="groups"
            value={formValues.groups}
            onChange={handleGroupChange}
            options={options.groups.map((group) => ({
              value: group.name,
              label: group.name,
            }))}
            styles={customSelectStyles}
            placeholder="Selectează grupurile..."
            isClearable
          />
        </div>
        {/* Subgroup */}
        <div className="flex flex-col flex-1 mt-4 md:mt-0">
          <label className="text-left mb-2 label-text">Subgrupă:</label>
          <Select
            name="subgroup"
            value={
              formValues.subgroup
                ? { value: formValues.subgroup, label: formValues.subgroup }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                subgroup: selectedOption?.value || '',
              })
            }
            options={[
              { value: 'subgroup1', label: 'Subgrupă 1' },
              { value: 'subgroup2', label: 'Subgrupă 2' },
            ]}
            styles={customSelectStyles}
            placeholder="Selectează subgrupa"
            isClearable
          />
        </div>
      </div>

      {/* Row 2 */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        {/* Subject */}
        <div className="flex flex-col flex-1">
          <label className="text-left mb-2 label-text">Subiect:</label>
          <Select
            name="subject"
            value={
              formValues.subject
                ? { value: formValues.subject, label: formValues.subject }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                subject: selectedOption?.value || '',
              })
            }
            options={options.subjects.map((subject) => ({
              value: subject.name,
              label: subject.name,
            }))}
            styles={customSelectStyles}
            placeholder="Alege Materia"
            isClearable
          />
        </div>
        {/* Professor */}
        <div className="flex flex-col flex-1 mt-4 md:mt-0">
          <label className="text-left mb-2 label-text">Profesor:</label>
          <Select
            name="professor"
            value={
              formValues.professor
                ? { value: formValues.professor, label: formValues.professor }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                professor: selectedOption?.value || '',
              })
            }
            options={options.professors.map((professor) => ({
              value: professor.name,
              label: professor.name,
            }))}
            styles={customSelectStyles}
            placeholder="Alege Profesorul"
            isClearable
          />
        </div>
      </div>

      {/* Row 3 */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        {/* Room */}
        <div className="flex flex-col flex-1">
          <label className="text-left mb-2 label-text">Cabinet:</label>
          <Select
            name="room"
            value={
              formValues.room
                ? { value: formValues.room, label: formValues.room }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                room: selectedOption?.value || '',
              })
            }
            options={options.rooms.map((room) => ({
              value: room.name,
              label: room.name,
            }))}
            styles={customSelectStyles}
            placeholder="Alege Cabinetul"
            isClearable
          />
        </div>
        {/* Time */}
        <div className="flex flex-col flex-1 mt-4 md:mt-0">
          <label className="text-left mb-2 label-text">Ora:</label>
          <Select
            name="time"
            value={
              formValues.time
                ? { value: formValues.time, label: formValues.time }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                time: selectedOption?.value || '',
              })
            }
            options={timeSlots.map((time) => ({
              value: time,
              label: time,
            }))}
            styles={customSelectStyles}
            placeholder="Alege Ora"
            isClearable
          />
        </div>
      </div>

      {/* Row 4 */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        {/* Lesson Type */}
        <div className="flex flex-col flex-1">
          <label className="text-left mb-2 label-text">Tip de Lecție:</label>
          <Select
            name="type"
            value={
              formValues.type
                ? { value: formValues.type, label: formValues.type }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                type: selectedOption?.value || '',
              })
            }
            options={[
              { value: 'curs', label: 'Curs' },
              { value: 'lab', label: 'Laborator' },
              { value: 'sem', label: 'Seminar' },
            ]}
            styles={customSelectStyles}
            placeholder="Alege tipul de Lecție"
            isClearable
          />
        </div>
        {/* Week Type */}
        <div className="flex flex-col flex-1 mt-4 md:mt-0">
          <label className="text-left mb-2 label-text">Tip de Săptămână:</label>
          <Select
            name="weekType"
            value={
              formValues.weekType
                ? { value: formValues.weekType, label: formValues.weekType }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                weekType: selectedOption?.value || '',
              })
            }
            options={[
              { value: 'both', label: 'Ambele' },
              { value: 'even', label: 'Săptămână Pară' },
              { value: 'odd', label: 'Săptămână Impară' },
            ]}
            styles={customSelectStyles}
            placeholder="Alege tipul de Săptămână"
            isDisabled={
              formValues.weekType === 'even' || formValues.weekType === 'odd'
            }
            isClearable
          />
        </div>
      </div>
    </form>
  </ConfirmationModal>
)}

{showEditModal && (
  <ConfirmationModal
    title="Editează perechea"
    message="Editează detaliile perechii."
    onConfirm={handleSave}
    onClose={() => setShowEditModal(false)}
    confirmLabel="Salvează"
    cancelLabel="Anulează"
    isDarkMode={isDarkMode}
  >
    <form className="space-y-4">
      {/* Row 1 */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        {/* Groups Multi-select */}
        <div className="flex flex-col flex-1">
          <label className="text-left mb-2 label-text">Grupuri:</label>
          <Select
            isMulti
            name="groups"
            value={formValues.groups}
            onChange={handleGroupChange}
            options={options.groups.map((group) => ({
              value: group.name,
              label: group.name,
            }))}
            styles={customSelectStyles}
            placeholder="Selectează grupurile..."
            isClearable
          />
        </div>
        {/* Subgroup */}
        <div className="flex flex-col flex-1 mt-4 md:mt-0">
          <label className="text-left mb-2 label-text">Subgrupă:</label>
          <Select
            name="subgroup"
            value={
              formValues.subgroup
                ? { value: formValues.subgroup, label: formValues.subgroup }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                subgroup: selectedOption?.value || '',
              })
            }
            options={[
              { value: 'subgroup1', label: 'Subgrupă 1' },
              { value: 'subgroup2', label: 'Subgrupă 2' },
            ]}
            styles={customSelectStyles}
            placeholder="Selectează subgrupa"
            isClearable
          />
        </div>
      </div>

      {/* Row 2 */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        {/* Subject */}
        <div className="flex flex-col flex-1">
          <label className="text-left mb-2 label-text">Subiect:</label>
          <Select
            name="subject"
            value={
              formValues.subject
                ? { value: formValues.subject, label: formValues.subject }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                subject: selectedOption?.value || '',
              })
            }
            options={options.subjects.map((subject) => ({
              value: subject.name,
              label: subject.name,
            }))}
            styles={customSelectStyles}
            placeholder="Alege Materia"
            isClearable
          />
        </div>
        {/* Professor */}
        <div className="flex flex-col flex-1 mt-4 md:mt-0">
          <label className="text-left mb-2 label-text">Profesor:</label>
          <Select
            name="professor"
            value={
              formValues.professor
                ? { value: formValues.professor, label: formValues.professor }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                professor: selectedOption?.value || '',
              })
            }
            options={options.professors.map((professor) => ({
              value: professor.name,
              label: professor.name,
            }))}
            styles={customSelectStyles}
            placeholder="Alege Profesorul"
            isClearable
          />
        </div>
      </div>

      {/* Row 3 */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        {/* Room */}
        <div className="flex flex-col flex-1">
          <label className="text-left mb-2 label-text">Cabinet:</label>
          <Select
            name="room"
            value={
              formValues.room
                ? { value: formValues.room, label: formValues.room }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                room: selectedOption?.value || '',
              })
            }
            options={options.rooms.map((room) => ({
              value: room.name,
              label: room.name,
            }))}
            styles={customSelectStyles}
            placeholder="Alege Cabinetul"
            isClearable
          />
        </div>
        {/* Time */}
        <div className="flex flex-col flex-1 mt-4 md:mt-0">
          <label className="text-left mb-2 label-text">Ora:</label>
          <Select
            name="time"
            value={
              formValues.time
                ? { value: formValues.time, label: formValues.time }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                time: selectedOption?.value || '',
              })
            }
            options={timeSlots.map((time) => ({
              value: time,
              label: time,
            }))}
            styles={customSelectStyles}
            placeholder="Alege Ora"
            isClearable
          />
        </div>
      </div>

      {/* Row 4 */}
      <div className="flex flex-col md:flex-row md:space-x-4">
        {/* Lesson Type */}
        <div className="flex flex-col flex-1">
          <label className="text-left mb-2 label-text">Tip de Lecție:</label>
          <Select
            name="type"
            value={
              formValues.type
                ? { value: formValues.type, label: formValues.type }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                type: selectedOption?.value || '',
              })
            }
            options={[
              { value: 'curs', label: 'Curs' },
              { value: 'lab', label: 'Laborator' },
              { value: 'sem', label: 'Seminar' },
            ]}
            styles={customSelectStyles}
            placeholder="Alege tipul de Lecție"
            isClearable
          />
        </div>
        {/* Week Type */}
        <div className="flex flex-col flex-1 mt-4 md:mt-0">
          <label className="text-left mb-2 label-text">Tip de Săptămână:</label>
          <Select
            name="weekType"
            value={
              formValues.weekType
                ? { value: formValues.weekType, label: formValues.weekType }
                : null
            }
            onChange={(selectedOption) =>
              setFormValues({
                ...formValues,
                weekType: selectedOption?.value || '',
              })
            }
            options={[
              { value: 'both', label: 'Ambele' },
              { value: 'even', label: 'Săptămână Pară' },
              { value: 'odd', label: 'Săptămână Impară' },
            ]}
            styles={customSelectStyles}
            placeholder="Alege tipul de Săptămână"
            isDisabled={
              formValues.weekType === 'even' || formValues.weekType === 'odd'
            }
            isClearable
          />
        </div>
      </div>
    </form>
  </ConfirmationModal>
)}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <ConfirmationModal
          title="Confirmare Ștergere"
          message="Sunteți sigur că doriți să ștergeți acest orar?"
          onConfirm={handleConfirmDelete}
          onClose={() => setShowDeleteModal(false)}
          confirmLabel="Șterge"
          cancelLabel="Anulează"
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
}

export default ScheduleManagement;
