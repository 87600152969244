// Timetable.js
import React, { useState, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import './css/Timetable.css';
import CurrentWeek from './CurrentWeek';
import AnimationSection from './../AnimationSection';

const Timetable = ({
  schedule = [],
  handleClearSchedules,
  onCellClick,
  isAdmin = false,
  handleDelete,
  selectedFilters = {},
  groupOptions = {}
}) => {
  const hasSaturdayClasses = groupOptions?.hasSaturdayClasses ?? false;
  const hasSixthLesson = groupOptions?.hasSixthLesson ?? false;

  const days = useMemo(() => {
    const baseDays = ['Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri'];
    const hasSaturdayEvents = schedule.some((event) => event.day === 'Sâmbătă');
    if (isAdmin || hasSaturdayClasses || hasSaturdayEvents) {
      return [...baseDays, 'Sâmbătă'];
    }
    return baseDays;
  }, [isAdmin, hasSaturdayClasses, schedule]);
 
// Define time slots based on admin status and group options
const timeSlots = useMemo(() => {
  const baseTimeSlots = [
    '08:00 - 09:30',
    '09:40 - 11:10',
    '11:20 - 12:50',
    '13:00 - 14:30',
    '14:40 - 16:10',
  ];
  const hasSixthLessonEvents = schedule.some((event) => event.time === '16:20 - 17:50');
  if (isAdmin || hasSixthLesson || hasSixthLessonEvents) {
    return [...baseTimeSlots, '16:20 - 17:50'];
  }
  return baseTimeSlots;
}, [isAdmin, hasSixthLesson, schedule]);



  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode === 'true' || false;
  });

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (isDarkMode) {
      rootElement.classList.add('dark-mode');
    } else {
      rootElement.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const currentWeekType = CurrentWeek();

  const { group, professor, room } = selectedFilters;

  const isOnlyGroupSelected = group && !professor && !room;
  const isOnlyProfessorSelected = !group && professor && !room;
  const isOnlyRoomSelected = !group && !professor && room;

  const handleCellClick = (day, time) => {
    if (isAdmin) {
      // Pass default values for weekType and subgroup to avoid errors
      onCellClick({ day, time });
    }
  };

  const handleEventClick = (event) => {
    if (isAdmin) {
      onCellClick({ day: event.day, time: event.time }, event);
    }
  };

  // Process schedule to combine events with same attributes
  const processedSchedule = useMemo(() => {
    const eventMap = {};

    schedule.forEach((event) => {
      const key = `${event.day}|${event.time}|${event.subject}|${event.professor}|${event.room}|${event.type}|${event.weekType}|${event.subgroup || 'none'}`;

      if (!eventMap[key]) {
        eventMap[key] = { ...event, groups: event.group ? [event.group] : [] };
      } else {
        if (event.group && !eventMap[key].groups.includes(event.group)) {
          eventMap[key].groups.push(event.group);
        }
      }
    });

    return Object.values(eventMap);
  }, [schedule]);

  // Helper function to determine if an event can be added to a cell
  const canAddEventToCell = (eventsInCell) => {
    if (eventsInCell.length === 0) {
      return true;
    } else if (eventsInCell.length === 1) {
      const existingEvent = eventsInCell[0];
      if (existingEvent.weekType === 'both' && !existingEvent.subgroup) {
        return false;
      } else if (existingEvent.weekType === 'both' && existingEvent.subgroup) {
        return true; // Allow adding event for other subgroup
      } else if (existingEvent.weekType === 'even' || existingEvent.weekType === 'odd') {
        return true; // Allow adding event of opposite weekType
      }
    }
    return false;
  };
  const daysWithEvents = useMemo(() => {
    // Dacă utilizatorul este admin, afișăm toate zilele
    if (isAdmin) {
      return days;
    }
  
    // Filtrăm zilele pentru a include doar cele care au evenimente
    return days.filter((day) =>
      schedule.some((event) => event.day === day)
    );
  }, [isAdmin, days, schedule]);

  // Afișăm animația doar pentru utilizatorii normali
if (!isAdmin && schedule.length === 0) {
  return (
    <AnimationSection
      title="Această grupă nu are orar 📆"
      message="Te rog selectează altă grupă"
    />
  );
}

  
  
  return (
<div
  className="timetable-container"
  style={{
    gridTemplateColumns: timeSlots.length === 5 ? 'repeat(6, 1fr)' : 'repeat(7, 1fr)',
  }}
>

      {window.innerWidth > 768 && (
        <>
          <div className="timetable-cell timetable-cell-header">
            {/* Clear Schedules Button (1x1 position) */}
            {isAdmin && group && (
              <button
                className="clear-schedules-button"
                onClick={handleClearSchedules}
              >
                Șterge toate Orarele
              </button>
            )}
          </div>
          {timeSlots.map((slot, index) => (
            <div key={index} className="timetable-cell timetable-cell-header">
              <span className="time-slot-counter">{index + 1}</span>{' '}
              {slot}
            </div>
          ))}
        </>
      )}

      {daysWithEvents.map((day, dayIndex) => (
        <React.Fragment key={dayIndex}>
          <div className="day-header">{day}</div>
          {timeSlots.map((slot, slotIndex) => {
            const eventsInCell = processedSchedule.filter(
              (event) => event.day === day && event.time === slot
            );
            const hasEvents = eventsInCell.length > 0;
            const canAddEvent = isAdmin && canAddEventToCell(eventsInCell);

            return (
              <div
                key={slotIndex}
                className={`timetable-cell ${
                  eventsInCell.length === 2 ? 'two-events' : ''
                }`}
                onClick={(e) => {
                  if (canAddEvent && !hasEvents) {
                    handleCellClick(day, slot);
                  }
                }}
                style={{
                  cursor: canAddEvent && !hasEvents ? 'pointer' : 'default',
                  position: 'relative',
                }}
              >
                {/* Display events */}
                {eventsInCell.map((event, index) => {
                  const isGrayedOut =
                    !isAdmin &&
                    ((event.weekType === 'odd' && currentWeekType === 'even') ||
                      (event.weekType === 'even' && currentWeekType === 'odd'));

                  let eventStyle = {};

                  if (event.weekType === 'even') {
                    eventStyle = {
                      borderLeft: '4px solid orange',
                      cursor: 'pointer',
                    };
                  } else if (event.weekType === 'odd') {
                    eventStyle = {
                      borderLeft: '4px solid #7CFC00',
                      cursor: 'pointer',
                    };
                  } else if (event.weekType === 'both') {
                    eventStyle = {
                      borderLeft: '4px solid #0288d1',
                      cursor: 'pointer',
                    };
                  }

                  return (
                    <div
                      key={index}
                      className={`timetable-event ${
                        isGrayedOut ? 'grayed-out' : ''
                      }`}
                      style={{
                        position: 'relative',
                        ...eventStyle,
                        ...(isGrayedOut && { opacity: 0.5 }),
                        ...(isAdmin && {
                          transition: 'transform 0.3s ease',
                        }),
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEventClick(event);
                      }}
                      onMouseEnter={(e) => {
                        if (isAdmin)
                          e.currentTarget.style.transform = 'scale(1.05)';
                      }}
                      onMouseLeave={(e) => {
                        if (isAdmin)
                          e.currentTarget.style.transform = 'scale(1)';
                      }}
                    >
                      {window.innerWidth <= 768 && (
                        <div className="time-slot">
                          <span className="time-slot-counter">
                            {slotIndex + 1}
                          </span>{' '}
                          {slot}
                        </div>
                      )}
                      <div className="subject">
                        {event.subject}{' '}
                        {window.innerWidth > 768 ? '-' : ''}{' '}
                        <span className="event-type">{event.type}</span>
                      </div>
                      {event.subgroup && event.subgroup !== null && (
  <div
    className="subgroup"
    style={{
      position: 'absolute',
      bottom:
        eventsInCell.length > 1
          ? window.innerWidth <= 768
            ? '10px'
            : '5px'
          : '15px',
      right: '10px',
      fontSize:
        eventsInCell.length > 1
          ? window.innerWidth <= 768
            ? '14px'
            : '10px'
          : '12px',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      padding: '2px 5px',
      borderRadius: '4px',
      color: '#333',
      zIndex: 10,
    }}
  >
    {`subgr. ${event.subgroup.slice(-1)}`}
  </div>
)}


                      <div className="professor">
                        {/* Conditional rendering based on selected filters */}
                        {isOnlyGroupSelected && `${event.professor}`}
                        {isOnlyProfessorSelected &&
                          `${
                            event.groups && event.groups.length > 0
                              ? event.groups.join(', ')
                              : event.group
                          }`}
                        {isOnlyRoomSelected && `${event.professor} `}
                        {!isOnlyGroupSelected &&
                          !isOnlyProfessorSelected &&
                          !isOnlyRoomSelected && (
                            <>
                              {event.professor}{' '}
                              {event.groups && event.groups.length > 0
                                ? event.groups.join(', ')
                                : event.group}
                            </>
                          )}
                      </div>
                      {isOnlyRoomSelected ? (
                        <div className="groups" >
                          {event.groups && event.groups.length > 0
                            ? event.groups.join(', ')
                            : event.group}
                        </div>
                      ) : (
                        <div className="room">{event.room}</div>
                      )}

                      {/* Only show delete button for admins */}
                      {isAdmin && (
                        <button
                          className="delete-button bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(event._id);
                          }}
                          style={{ cursor: 'pointer' }}
                          onMouseEnter={(e) => {
                            e.stopPropagation();
                            e.currentTarget.parentElement.style.borderLeft =
                              '5px solid red';
                          }}
                          onMouseLeave={(e) => {
                            e.stopPropagation();
                            e.currentTarget.parentElement.style.borderLeft =
                              eventStyle.borderLeft;
                          }}
                        >
                          Șterge
                        </button>
                      )}
                    </div>
                  );
                })}

                {/* Add "+" button if we can add another event */}
                {canAddEvent && (
                  <button
                    className="add-event-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCellClick(day, slot);
                    }}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      backgroundColor: isDarkMode ? '#444' : '#fff',
                      border: 'none',
                      cursor: 'pointer',
                      padding: '2px',
                      borderRadius: '50%',
                      display: 'flex',
                    }}
                  >
                    <span
                      className="material-icons"
                      style={{ fontSize: '18px', color: '#007bff' }}
                    >
                      add_circle
                    </span>
                  </button>
                )}

                {/* If no events for the current time slot, show available icon */}
                {!hasEvents &&
                  (isAdmin ? (
                    <span
                      className="material-icons available-icon"
                      style={{ cursor: 'pointer', color: '#ccc' }}
                    >
                      edit_calendar
                    </span>
                  ) : (
                    <span className="material-icons available-icon">
                      free_breakfast
                    </span>
                  ))}
              </div>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
};

Timetable.propTypes = {
  schedule: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCellClick: PropTypes.func,
  isAdmin: PropTypes.bool,
  handleDelete: PropTypes.func,
  selectedFilters: PropTypes.object,
  groupOptions: PropTypes.object,
};

export default memo(Timetable);
