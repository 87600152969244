import React from 'react';
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({ children, token }) {
  // Check if the token exists (authentication check)
  if (!token) {
    return <Navigate to="/login" />;
  }

  // If the token exists, render the requested component
  return children;
}
