// Login.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './Login.css';
// Function to handle login request

async function loginUser(credentials) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
    credentials: 'include', // Include credentials
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Login failed');
    }
    return response.json();
  });
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await loginUser({
        username,
        password,
      });
      setToken(data);
      navigate('/admin/welcome');
    } catch (error) {
      setErrorMessage('Invalid username or password');
    }
  };

  return (
    <div className="page-login min-h-screen flex items-center justify-center">
      <div className="container-login shadow-lg rounded-lg p-8 w-full max-w-sm lg:max-w-md xl:max-w-md h-auto space-y-6">
        <h1 className="h1-login text-2xl font-bold text-gray-800 text-center">
          Please Log In
        </h1>
        {errorMessage && (
          <p className="text-red-500 text-center mb-4">{errorMessage}</p>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="username"
              className="label-login block text-sm font-medium text-gray-800"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              className="input-login mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-900 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="label-login block text-sm font-medium text-gray-800"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-login mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-900 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
